import React from 'react';
import PropTypes from 'prop-types';

import ArrowButton from 'components/buttons/ArrowButton';

import InternalLink from 'components/links/internalLink';

import { StyledPressReleasesWrapper } from 'styled';

function List({ localisedPressReleaseDocuments, preview, mainUrl, language, pressReleasesToShow, handlePressPagination }) {

  const dateOptions = { day: '2-digit', month: 'long', year: 'numeric' };

  const pressReleasesList = localisedPressReleaseDocuments.sort((a, b) => {
    const dateA = a.node.data.date_of_publication
    const dateB = b.node.data.date_of_publication

    return new Date(dateB) - new Date(dateA)
    })
    .filter(el => el.node.data.date_of_publication)
    .slice(0, pressReleasesToShow)
    .map(el => {
      const title = preview ? el.node.data.document_title[0].text: el.node.data.document_title.text;
      const articleUrl = el.node.data.url;
      
      const datePublished = new Date(el.node.data.date_of_publication).toLocaleDateString(process.env.LANG_CODE, dateOptions).split(' ');
      const formattedPublishDate = `${datePublished[0]} ${datePublished[1]} ${datePublished[2]}`;

      return (
        <div key={articleUrl} className="press-release-articles">
          <InternalLink 
            path={`/${mainUrl}/${articleUrl}`} 
            linkText={title} 
            language={language}
          /> - {formattedPublishDate}
        </div>
      )
    })

  return (
    <StyledPressReleasesWrapper>
      { pressReleasesList }
      
      {pressReleasesToShow < localisedPressReleaseDocuments.length ? (
        <ArrowButton 
          buttonText={process.env.LANG_CODE=='fr-fr' || language === 'fr-fr' ? 'VOIR PLUS' : 'LOAD MORE'}
          buttonHref="link"
          theme="red"
          onClick={handlePressPagination}
          isLink={false}
        /> 
      ): null 
      }
    </StyledPressReleasesWrapper>
  )
}

List.propTypes = {
  handlePressPagination: PropTypes.func,
  language: PropTypes.string,
  localisedPressReleaseDocuments: PropTypes.array,
  mainUrl: PropTypes.string,
  pressReleasesToShow: PropTypes.number,
  preview: PropTypes.bool
}

export default List

